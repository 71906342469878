import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import IndividualHubPageLayoutTemplate from '../components/IndividualHubPageLayoutTemplate'

export const IndustriesTemplate = ({
  industriesList,
  spotlightActivity,
  pageBannerTitle,
  pageBannerDescription,
  pageBannerImage
  }) => {


  return (
    <>
      <IndividualHubPageLayoutTemplate 
        gridList={industriesList}
        spotlightActivity={spotlightActivity}
        pageBannerTitle={pageBannerTitle}
        pageBannerDescription={pageBannerDescription}
        pageBannerImage={pageBannerImage}
      />
  
    </>
  )
}

IndustriesTemplate.propTypes = {
    // bannerImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    industriesList: PropTypes.array,
    spotlightActivity: PropTypes.array,
    pageBannerTitle: PropTypes.string,
    pageBannerDescription: PropTypes.string,
    pageBannerImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }

  const IndustriesPage = ({ data }) => {
      const { frontmatter } = data.markdownRemark
      return (
        <Layout
        title={frontmatter.pageBannerTitle}
        description={frontmatter.pageBannerDescription}
        >
              <IndustriesTemplate
                industriesList={frontmatter.industriesList}
                spotlightActivity={frontmatter.spotlightActivity}
                pageBannerTitle={frontmatter.pageBannerTitle}
                pageBannerDescription={frontmatter.pageBannerDescription}
                pageBannerImage={frontmatter.pageBannerImage}
                 
              />
        </Layout>
      )
}


IndustriesPage.propTypes = {
    data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    }),
}

export default IndustriesPage


export const pageQuery = graphql`
  query IndustryPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "industries-hub" } }) {
      frontmatter {
        pageBannerTitle,
        pageBannerDescription,
        pageBannerImage {
          extension
          publicURL
        }
        industriesList {
          title
          link
          thumbnail {
              extension
              publicURL
            }
        }
        spotlightActivity {
          title
          link
          description
          thumbnail {
              extension
              publicURL
            }
        }
      }
    }
  }
`
